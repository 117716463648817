import { Environment } from 'src/app/shared/types/environment.type';

export const environment: Environment = {
  code: 'nlf',
  loginBackgroundImage: 'LoginBackground.nlf.jpg',
  loginLogoImage: 'app_icon.png',
  appIcon: 'app_icon.png',
  hostUrl: 'https://nlf-api.manydigital.com/',
  production: true,
  clubName: 'Norsk Ligafotball',
  primaryColor: '#005192',
  secondaryColor: '#DA1A31',
  pushDefaultLanguage: 'no',
  supportedLanguages: ['en', 'de'],
  preferredLanguage: 'no',
  firebaseConfig: {
    apiKey: 'AIzaSyBjetGsMkjAnGtMo8JEtJvDFWAKGWf2dzA',
    authDomain: 'nlf-firebase.firebaseapp.com',
    projectId: 'nlf-firebase',
    storageBucket: 'nlf-firebase.appspot.com',
    messagingSenderId: '738363929582',
    appId: '1:738363929582:web:709a8012e0a63e3020d4ff',
    measurementId: 'G-CVKB7EMDCS',
  },
};

export const richTextConfig = {
  style: {
    fontFamily: 'Montserrat Light, sans-serif',
    fontWeight: 300,
    textAlign: 'center',
    font: '16px Montserrat Light',
  },
  font: {
    list: {
      'Montserrat Light': 'Montserrat Light',
      'Montserrat Bold': 'Montserrat Bold',
      'Montserrat ExtraBold': 'Montserrat ExtraBold',
    },
  },
};

export const firebaseConfig = {
  apiKey: 'AIzaSyBjetGsMkjAnGtMo8JEtJvDFWAKGWf2dzA',
  authDomain: 'nlf-firebase.firebaseapp.com',
  projectId: 'nlf-firebase',
  storageBucket: 'nlf-firebase.appspot.com',
  messagingSenderId: '738363929582',
  appId: '1:738363929582:web:709a8012e0a63e3020d4ff',
  measurementId: 'G-CVKB7EMDCS',
};
